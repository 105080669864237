
$body-bg:rgba(54,201,95,.05);

$theme-colors: (
  success: #36c95f,
  primary:#20c997,
  secondary:#a336c9,
  danger:#f46b68,
  warning:#ffb800,
  info:#369dc9,
  light:#d1d1d1,
  dark:#3e4954
  );

@import '~bootstrap/scss/bootstrap';

  .btn-primary, .btn-success, .btn-danger, .btn-info  {
    color: #fff;
       
}
.btn-primary:hover,.btn-success:hover, .btn-danger:hover, .btn-info:hover {
  color: #fff;
}

.card{
  margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all .5s ease-in-out;
    position: relative;
    border: 0 solid transparent;
    border-radius: 0.75rem;
    box-shadow: 0 12px 33px 0 rgb(62 73 84 / 8%);
    height: calc(100% - 30px);
} 

.content{
  display: flex;
align-items: center;
justify-content: center;
  min-height: 80vh;
}

